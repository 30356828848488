import { Box } from "@mui/material";
import React from "react";
import Aboutus from "../components/aboutus/Aboutus";

const Aboutuspage = () => {
  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Aboutus />
      </Box>
      
    </div>
  );
};
export default Aboutuspage;
