import React from "react";
import "./Contactsus.css";

const Contactsus = () => {
  return (
    <>
      <p>
        <strong> Bangladesh office </strong>
      </p>
      <p>
      
KAMRUL HASAN <br />
Astral Palace, Plot # 27, Road # 08
        <br />
        Sector 11, Uttara
        <br />
        Dhaka-1230 , BANGLADESH.
        <br />
        Contact No : +8801713363078
        <br />
        Mail: kamrul@fashion2morrow.com
      </p>
      <br />
      <br />
      <p>
        <strong>Italian office</strong>
      </p>
      <p>
      DEWAN PALAS
        <br />
        Via Francesco, selmi 77, 00156 Roma, Italy
        <br />
        Mail : Dewan@Fashion2morrow.com
        <br />
        Contact No :  +39 388 999 4987
      </p>
      <br />
      <br />
      <p>
        <strong>France office</strong>
      </p>
      <p>
      DELPHINE CANNICCIONI
        <br />
        2-4 cours tarbe 89100 Sens France
        <br />
        Mail : delphine@fashion2morrow.com
        <br />
        Contact No :  0033615093313
      </p>
      <br />
      <br />
      <p>
        Web : www.fashion2morrow.com ,<br />
        E-Mail : info@Fashion2Morrow.com 
      </p>
    </>
  );
};

export default Contactsus;
